import { template as template_049ce6e1b64944fe9774c97871df96a6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_049ce6e1b64944fe9774c97871df96a6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_334ffddd87404d3f858280d97551f566 } from "@ember/template-compiler";
const WelcomeHeader = template_334ffddd87404d3f858280d97551f566(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

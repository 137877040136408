import { template as template_31ec51cd0332487fa3fcbdf9c0651842 } from "@ember/template-compiler";
const FKControlMenuContainer = template_31ec51cd0332487fa3fcbdf9c0651842(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
